.container {
  position: relative;
  display: flex;
}

.content {
  flex: 1;
  max-width: 100%;
}

.superBanner {
  border: none;
  background: url(https://s3.amazonaws.com/orion-eat-app-files/orioneat-prod%2FaZZnRdptvWYaYKGGY-fondo_pedir.jpg);
  min-height: 100vh;
  background-color: black;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 550px;
}

@media (max-width: 768px) {
  .superBanner {
    min-height: 83vh;
    background-size: 310px;
  }
}
