.deliveryInfo {
  display: flex;
  flex-direction: column;
  position: relative;
}

.typeOfDelivery {
  margin-bottom: 4px;
  display: flex;
}

.typeOfDelivery .icon {
  margin-right: 8px;
}

.addressInfo {
  font-weight: bold;
  cursor: pointer;
}

.loading {
  height: 22px;
  width: 150px;
  background: #eee;
  composes: loadingGlow from global;
}

.container {
  composes: content from global;
  box-shadow: 0 4px 35px rgba(50, 50, 93, 0.1), 0 8px 15px rgba(0, 0, 0, 0.07) !important;
  padding: 0 !important;
  position: absolute;
  top: 80px;
  min-width: 335px;
  z-index: 2000;
}

.options {
  composes: container;
  right: 0;
  left: 0;
}

.optionsContent {
  padding: 15px;
}

@media (max-width: 1000px) {
  .deliveryInfo {
    justify-content: center;
    width: 100%;
  }

  .typeOfDelivery {
    margin-bottom: 0;
    min-width: 120px;
    margin-right: 8px;
  }

  .addressInfo {
    max-width: 180px;
  }

  .container {
    top: 60px;
  }
}
