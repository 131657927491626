.container {
  text-align: center;
  padding: 10px 0;
  height: calc(100vh - 165px);
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  justify-items: center;
  align-items: center;
  padding-bottom: 10px;
}

.linkContainer {
  position: relative;
}

.cartCount {
  position: absolute;
  right: 6px;
  top: -2px;
  background-color: red;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  border-radius: 100%;
  font-size: 12px;
}

.link {
  composes: appSubtitle from global;
  display: block;
  font-weight: bold;
  font-size: 15px !important;
  color: inherit;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0px !important;
  position: relative;
}

.link:hover {
  color: #fff;
}

.link::before {
  content: '';
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 11px;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}

.link:hover::before {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(256deg) brightness(102%) contrast(101%);
}

.link:nth-child(1)::before {
  background: url(https://s3.amazonaws.com/orion-eat-app-files/orioneat-prod%2F9Y8RQAvm77LpMKD4j-delivery.svg);
}
.link:nth-child(2)::before {
  background: url(https://s3.amazonaws.com/orion-eat-app-files/orioneat-prod%2FNjpNtpZYPQDDcCnfX-productos.svg);
}
.link:nth-child(3)::before {
  background: url(https://s3.amazonaws.com/orion-eat-app-files/orioneat-prod%2FWJJ2GSynmnCmdk8rr-pedido.svg);
}
.link:nth-child(4)::before {
  background: url(https://s3.amazonaws.com/orion-eat-app-files/orioneat-prod%2FfoR8jG9joGtcnenTS-contacto.svg);
}
.link:nth-child(5)::before {
  background: url(https://s3.amazonaws.com/orion-eat-app-files/orioneat-prod%2FccWbN6jFNpManLQEs-asistencia.svg);
}

.mobileMenu {
  display: none !important;
}

@media (max-width: 768px) {
  .link::before {
    content: none;
  }

  .cartCount {
    display: none;
  }

  .mobileMenu {
    display: grid !important;
  }

  .mobileMenu .link {
    background-color: black;
    color: #606060;
  }

  .link {
    width: 100%;
    height: 100%;
    border-bottom: 1px solid rgb(96, 96, 96);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left !important;
    padding: 0 36px;
    background-color: red;
    color: black;
  }

  .container {
    grid-template-rows: 45px;
    grid-auto-rows: 45px;
    padding: 0;
    height: auto;
  }
}
