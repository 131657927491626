.container {
  position: sticky;
  top: 0;
  width: 115px;
}

.sidebarSmall .container {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 600;
  background-color: black;
  width: 100vw;
  overflow: auto;
}

.shadow {
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  position: fixed;
  z-index: 500;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.flex {
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.sections {
  flex: 1;
}
