.container {
  height: 165px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  border-bottom: 1px solid rgb(96, 96, 96);
}

.container a {
  text-align: center;
  max-width: 55px;
}

@media (max-width: 500px) {
  .container {
    justify-content: flex-start;
    padding-left: 20px;
  }
  .container a {
    text-align: left;
  }
}

.container img {
  max-height: 100px;
  max-width: 100%;
  box-sizing: border-box;
  padding: 5px 0;
}

.mobileHeader {
  display: none;
}

.mobileHeader .cart {
  width: 28px;
  position: relative;
}

.mobileHeader .burgerMenu {
  width: 44px;
}

.mobileHeader img {
  width: 100%;
}

.cartCount {
  position: absolute;
  right: 0;
  top: 0;
  background-color: red;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  border-radius: 100%;
  font-size: 12px;
}

@media (max-width: 768px) {
  .container {
    height: 60px;
    padding: 0;
  }

  .container a img {
    max-height: 40px;
  }

  .container > a {
    border-right: 1px solid rgb(96, 96, 96);
    height: 100%;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobileHeader {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0 14px;
  }
}
