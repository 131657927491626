.accountInfo {
  display: flex;
  color: var(--navbar-text-color);
  font-size: 16px;
  align-items: center;
  cursor: pointer;
  position: relative;
  font-weight: bold;
}

.accountInfo .icon {
  margin-right: 6px;
  display: flex;
  align-items: center;
}

.container {
  composes: content from global;
  box-shadow: 0 4px 35px rgba(50, 50, 93, 0.1), 0 8px 15px rgba(0, 0, 0, 0.07) !important;
  padding: 0 !important;
  position: absolute;
  top: 10px;
  min-width: 200px;
  z-index: 2000;
}

.options {
  composes: container;
  left: 0;
}

@media (max-width: 1000px) {
  .accountInfo {
    font-size: 14px;
    margin: 0 auto;
  }
}
